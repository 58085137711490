import {Loader} from "@googlemaps/js-api-loader";
import {MarkerClusterer} from "@googlemaps/markerclusterer";


// ==============================
// Interactive Map
// ==============================

let map;
let map_markers = [];
let info_windows = [];
let markerCluster;

const url = "https://maps.googleapis.com/maps/api/staticmap";
// @ts-ignore google.maps.plugins
const loader = new Loader({
    apiKey: "AIzaSyA54eqFDWhMbzmpbiWtvVHLnjRgXLNJwqc",
    version: "weekly",
});

// Configs
let map_config = {
    zoom: 8,
    center: {lat: 46.7676208, lng: 8.4780857},
    mapId: "627018de24ff1a7d",
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    maxZoom: 15,
    minZoom: 8,
};

let marker_config = {
    size: {
        x: 22,
        y: 33,
    },
    anchor: {
        x: 11,
        y: 33,
    },
    origin: {
        x: 0,
        y: 0,
    }
};

let cluster_config = {
    size: {
        x: 30,
        y: 30,
    },
    anchor: {
        x: 15,
        y: 15,
    },
    textColor: "black",
    fontSize: "12px",
    fontWeight: "bold",
};

// used for cluster
let custom_renderer = {
    render: ({count, position}) =>
        new google.maps.Marker({
            label: {
                text: String(count),
                color: cluster_config.textColor,
                fontSize: cluster_config.fontSize,
                fontWeight: cluster_config.fontWeight,
            },
            icon: {
                url: "/static/images/map/cluster.png",
                scaledSize: new google.maps.Size(cluster_config.size.x, cluster_config.size.y),
                textDecoration: "none",
                fontFamily: "Arial,sans-serif",
                backgroundPosition: "0 0",
                anchor: new google.maps.Point(cluster_config.anchor.x, cluster_config.anchor.y),
            },
            position,
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        }),
};

document.addEventListener("DOMContentLoaded", () => {
    const mapEl = document.getElementById("anex-map");
    if (mapEl) {
        loader.load().then(() => {
            map = new google.maps.Map(mapEl, map_config);

            // create the infowindow markup
            projects.forEach((project, index) => {
                var detail_linkMarkup = "";

                if(project.has_detail == "True"){
                    detail_linkMarkup =
                        '<a href="' + project.detail_url + '" target="_blank" class="btn btn-default btn-md button-link-plugin__link" role="button">' +
                        '<span class="link-text"><span>Projekt ansehen</span></span>' +
                        '<span class="link-text-mask">' +
                        '<span class="link-text-mask__inner">' +
                        '<span data-mask-label="Projekt ansehen">' +
                        '</span>' +
                        '</span>' +
                        '</span>' +
                        '</a>';
                }

                // Calc the focal point of the preview image
                let focalPoint = project.image_data.location.split(",");

                if(focalPoint.length == 2){
                    // Has focal point set
                    project.image_data.focalPoint.x = calcFocalPoint(project.image_data.width, focalPoint[0]);
                    project.image_data.focalPoint.y = calcFocalPoint(project.image_data.height, focalPoint[1]);
                } else {
                    // No focal point set, default to center
                    project.image_data.focalPoint.x = 50;
                    project.image_data.focalPoint.y = 50;
                }

                let popupMarkup =
                    '<div class="map-popup">' +
                    '<div class="map-popup--image">' +
                    '<img src="' + project.image + '" alt="' + project.title + '" style="object-position: ' + project.image_data.focalPoint.x + '% ' + project.image_data.focalPoint.y + '%" />' +
                    '</div>' +
                    '<div class="map-popup--head">' +
                    '<img class="map-popup--clientLogo" src="' + project.client.image + '" alt="' + project.client.name + '" />' +
                    '<h3 class="map-popup--title">' + project.title + '</h3>' +
                    '</div>' +
                    '<div class="map-popup--body">' +
                    '<div class="map-popup--services_label">Leistungsumfang Anex</div>' +
                    '<div class="map-popup--services_value">' + project.services + '</div>' +
                    detail_linkMarkup +
                    '</div>' +
                    '</div>';

                var infowindow = new google.maps.InfoWindow({
                    content: popupMarkup
                });
                info_windows.push(infowindow);

                // pick the right marker image for each project pin
                var markerCategory = project.categories[0];

                if(project.map_icon){
                    markerCategory = project.map_icon;
                }

                switch (markerCategory) {
                    case "spital":
                        var markerURL = "/static/images/map/pin-spital.png";
                        break;
                    case "energie":
                        var markerURL = "/static/images/map/pin-energie.png";
                        break;
                    case "industrie":
                        var markerURL = "/static/images/map/pin-industrie.png";
                        break;
                    case "denkmalgeschutztes-gebaude":
                        var markerURL = "/static/images/map/denkmalgeschutztes-gebaude.png";
                        break;
                    case "hotel":
                        var markerURL = "/static/images/map/pin-hotel.png";
                        break;
                    case "photovoltaik":
                        var markerURL = "/static/images/map/pin-photovoltaik.png";
                        break;
                    case "bauherrenberatung":
                        var markerURL = "/static/images/map/pin-bauherrenberatung.png";
                        break;
                    case "andere":
                        var markerURL = "/static/images/map/pin-andere.png";
                        break;
                    default:
                        var markerURL = "/static/images/map/pin-fallback.png";
                        break;
                }

                var markerIcon = {
                    url: markerURL,
                    scaledSize: new google.maps.Size(marker_config.size.x, marker_config.size.y),
                    origin: new google.maps.Point(marker_config.origin.x, marker_config.origin.x),
                    anchor: new google.maps.Point(marker_config.anchor.x, marker_config.anchor.y)
                };

                var newMarker = new google.maps.Marker({
                    map: map,
                    title: project.title,
                    position: project.position,
                    category: project.categories,
                    size: new google.maps.Size(20, 20),
                    icon: markerIcon,
                });

                newMarker.addListener("click", () => {
                    // close all other info windows
                    info_windows.forEach((infowindow) => {
                        infowindow.close();
                    });

                    // open the clicked info window
                    infowindow.open({
                        anchor: newMarker,
                        map,
                    });
                });
                map_markers.push(newMarker);
            });

            if(getFilterParameter()){
                filter_markers(getFilterParameter());
                createClusters(map, map_markers, custom_renderer);
                updateClusters(map, map_markers, custom_renderer);
            }else{
                createClusters(map, map_markers, custom_renderer);
            }
        });


        const filterDropdown = document.getElementById('map_category--select');

        filterDropdown.addEventListener('change', function (e) {
            filter_markers(this.value);
            updateClusters(map, map_markers, custom_renderer);
        });
    }
});




function createClusters(map, markers, renderer) {
    markerCluster = new MarkerClusterer({
        map: map,
        markers: markers,
        renderer: renderer,
    });
}

function filter_markers(category) {
    for (var i = 0; i < map_markers.length; i++) {
        var marker = map_markers[i];

        // same category or category not picked
        if ((typeof marker.category == 'object' && marker.category.indexOf(category) >= 0) || category.length == 0 || category == "all") {
            marker.setVisible(true);
        } else { // no match
            marker.setVisible(false);
        }
    }
}

function updateClusters(map, markers, renderer) {
    markerCluster.clearMarkers();
    markerCluster = new MarkerClusterer({
        map: map,
        markers: markers.filter(marker => marker.visible),
        renderer: renderer,
    });
}

function getFilterParameter() {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let filterValue = urlParams.get('filter');

    if(validateFilter(filterValue)){
        document.getElementById("map_category--select").value = filterValue;
        $("#map_category--select").selectpicker('refresh');
        return filterValue;
    }else{
        return "all";
    }
}

function validateFilter(filterVal){
    const validFilters = [
        'all',
        'spital',
        'energie',
        'industrie',
        'denkmalgeschutztes-gebaude',
        'hotel',
        'photovoltaik',
        'bauherrenberatung',
        'andere'
    ];

    if(validFilters.includes(filterVal)){
        return true;
    }else{
        return false;
    }
}

function calcFocalPoint(x, fx) {
    var fx = parseInt(fx);
    var x = parseInt(x);
    return Math.round(fx / (x / 100));
}
